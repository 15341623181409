<template>
  <div class="col-10 sm:col-8 md:col-6 lg:col-5 xl:col-4">
    <Card>
      <template #header>
        <h1 class="display-2 text-center">Se connecter</h1>
        <div class="p-error text-center text-sm" v-if="error">
          {{ error.message }}
        </div>
      </template>
      <template #content>
        <form @submit.prevent="onSubmit(!v$.$invalid)" id="form">
          <div class="p-field">
            <label>Adresse email</label>
            <div class="p-inputgroup">
              <span
                class="p-inputgroup-addon"
                :class="v$.email.$invalid && v$.email.$dirty ? 'danger' : ''"
              >
                <i class="fa fa-user"></i>
              </span>
              <InputText
                type="email"
                placeholder="Adresse email"
                v-model="v$.email.$model"
                :class="{
                  'p-invalid': v$.email.$invalid && v$.email.$dirty,
                }"
              />
            </div>
          </div>
          <div class="p-field">
            <label>Mot de passe</label>
            <div class="p-inputgroup">
              <span
                class="p-inputgroup-addon"
                :class="
                  v$.password.$invalid && v$.password.$dirty ? 'danger' : ''
                "
              >
                <i class="fa fa-lock"></i>
              </span>
              <Password
                :feedback="false"
                toogleMask
                v-model="v$.password.$model"
                placeholder="Mot de passe"
                :class="{
                  'p-invalid': v$.password.$invalid && v$.password.$dirty,
                }"
              />
            </div>
          </div>
          <div class="p-fluid mt-3">
            <ToggleButton
              v-model="remember"
              onIcon="pi pi-check"
              offIcon="pi pi-times"
              class="text-sm mr-2"
              onLabel="Se souvenir de moi"
              offLabel="Se souvenir de moi"
            ></ToggleButton>
          </div>
        </form>
      </template>
      <template #footer>
        <div class="p-fluid">
          <Button
            class="p-button-primary"
            type="submit"
            form="form"
            :label="pending ? 'Connexion' : 'Se connecter'"
            :loading="pending"
            icon="pi pi-sign-in"
            iconPos="right"
            :disabled="pending || v$.$invalid"
          ></Button>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { version } from "../../../package.json";
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import useAuth from "../../composables/useAuth";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup() {
    const router = useRouter();
    const { login, error, pending } = useAuth();

    const input = reactive({
      email: undefined,
      password: undefined,
      remember: false,
    });

    const resetForm = () => {
      input.email = undefined;
      input.password = undefined;
      input.remember = false;
    };

    const rules = {
      email: {
        required: helpers.withMessage(
          "Saisissez votre adresse email",
          required
        ),
      },
      password: {
        required: helpers.withMessage("Saisissez votre mot de passe", required),
      },
    };

    const v$ = useVuelidate(rules, input);

    const onSubmit = async (isFormValid) => {
      if (isFormValid) {
        await login(input);
        if (!error.value) {
          await router.push({ name: "Homepage" });
          resetForm();
        } else {
          input.password = undefined;
        }
      }
    };

    return {
      ...toRefs(input),
      v$,
      onSubmit,
      error,
      version,
      pending,
    };
  },
};
</script>

<style lang="scss" scoped>
.p-card {
  border: none;
  box-shadow: none;
}
</style>
